export default {
  name: "title-page",
  props: {
    title: { required: true, type: String },
    iconClass: { required: true, type: String },
    titleButton: { default: 'Voltar', type: String },
    routeButton: { default: null, type: String },
    paramsButton: { default: () => {}, type: Object }
  },
};
